import React, { useState } from "react";
import { Client } from "@twilio/conversations";
import { ChevronDoubleLeftIcon } from "@twilio-paste/icons/esm/ChevronDoubleLeftIcon";
import { Box, Input } from "@twilio-paste/core";
import { ChevronDoubleRightIcon } from "@twilio-paste/icons/esm/ChevronDoubleRightIcon";

import CreateConversationButton from "./CreateConversationButton";
import ConversationsList from "./ConversationsList";
import styles from "../../styles";

import { useDispatch } from "react-redux";
import { filterConversations } from "./../../store/action-creators";

interface ConvosContainerProps {
  client?: Client;
  setConversationVisible: (visible: boolean) => void;
  isConversationVisible: boolean;
}

const ConversationsContainer: React.FC<ConvosContainerProps> = (
  props: ConvosContainerProps
) => {
  const [listHidden, hideList] = useState(false);
  const dispatch = useDispatch();
  const [isConversationVisible, setConversationVisible] = useState(false);

  const handleSearch = (searchString: string) => {
    dispatch(filterConversations(searchString));
  };

  return (
    <Box
      style={
        listHidden
          ? { ...styles.convosWrapper, ...styles.collapsedList }
          : styles.convosWrapper
      }
    >
      <Box style={styles.collapseButtonBox}>
        <Box
          paddingTop="space30"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onClick={() => hideList(!listHidden)}
        >
          {listHidden ? (
            <ChevronDoubleRightIcon decorative={false} title="Collapse" />
          ) : (
            <ChevronDoubleLeftIcon decorative={false} title="Collapse" />
          )}
        </Box>
      </Box>
      {listHidden ? null : (
        <Box marginTop="space60" style={styles.searchBox}>
          <Input
            aria-describedby="convo_string_search"
            id="convoString"
            name="convoString"
            type="text"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            required
            autoFocus
          />
        </Box>
      )}

      <Box style={styles.convoList} onClick={() => hideList(!listHidden)}>
        {!listHidden ? (
          <ConversationsList
            isConversationVisible={isConversationVisible}
            setConversationVisible={setConversationVisible}
          />
        ) : null}
      </Box>
      <Box style={styles.newConvoButton}>
        <CreateConversationButton
          client={props.client}
          collapsed={listHidden}
        />
      </Box>
    </Box>
  );
};

export default ConversationsContainer;
